<template>
  <div class="container-app">
    <div class="container-content-app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {v4 as uuidv4} from 'uuid';
import {useSettings} from "@/stores/settings";

export default {
  name: 'App',
  setup() {
    useSettings().getServiceAccountCredentials()
        .then(() => {
        })
        .catch(() => {
        })

    useSettings().getSettings({queryParams: null})
        .then(() => {
        })
        .catch(() => {
        })
    return {}
  },
  data() {
    return {}
  },
  components: {},
  created() {
    useApiSettingStore().isMobile = false
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.self !== window.top) {
      useApiSettingStore().isMobile = true
    }

    if (window.self !== window.top) {
      useApiSettingStore().isInIframe = true
      document.body.style.background = 'transparent'
    }
  },
  updated() {
  },
  mounted() {

    let app = document.getElementById('app')

    document.body.style.height = window.innerHeight + 'px'
    app.style.height = window.innerHeight + 'px'
    useApiSettingStore().sessionID = uuidv4()
    useApiSettingStore().userID = uuidv4()

    useApiSettingStore().isMobile = false
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.self !== window.top) {
      useApiSettingStore().isMobile = true
    }

    if (window.self !== window.top) {
      useApiSettingStore().isInIframe = true
      document.body.style.background = 'transparent'
    }
  },
  methods: {
  }
}
</script>

<style>
.container-app {
  height: 100%;
}

.container-content-app {
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  height: 100%;
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}


</style>